import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonCompass2 = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'compass 2 icon'}</title>
      <polygon points='15,1 1,6 8,8 10,15' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
