import styled, { css } from 'styled-components';
import { glsp, multiply, themeVal } from '@devseed-ui/theme-provider';

import { createHeadingStyles, createSubtitleStyles } from '.';

export const renderTableZebra = ({ zebra } = {}) =>
  zebra &&
  css`
    tbody tr:nth-of-type(odd) {
      background: ${themeVal('color.base-50a')};
    }
  `;

export const createTableStyles = (props) => css`
  width: 100%;
  max-width: 100%;
  border-color: ${themeVal('color.base-200a')};
  border-collapse: collapse;

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-bottom-color: inherit;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 0px;
  }

  & > *:not(caption) > * > * {
    border-bottom-width: ${themeVal('layout.border')};
  }

  & > :not(:last-child) > :last-child > * {
    border-bottom-width: ${multiply(themeVal('layout.border'), 2)};
  }

  th {
    ${createHeadingStyles({ size: 'xsmall' })}
  }

  td,
  th {
    padding: ${glsp(1 / 4)};
    vertical-align: top;
    text-align: left;
  }

  th:first-child,
  td:first-child {
    padding-left: ${glsp()};
  }

  th:last-child,
  td:last-child {
    padding-right: ${glsp()};
  }

  /* Table zebra */
  ${renderTableZebra(props)}

  caption {
    ${createSubtitleStyles}
    caption-side: bottom;
    padding: ${glsp()};
    text-align: center;
  }
`;

export const Table = styled.table`
  ${createTableStyles}
`;
