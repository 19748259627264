import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonSwapHorizontal = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'swap horizontal icon'}</title>
      <path d='M0,11h11.2l-2.6,2.6L10,15l6-6H0V11z M4.8,5l2.6-2.6L6,1L0,7h16V5H4.8z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
