import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonPopUp = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'pop up icon'}</title>
      <path d='M4,2v9h10V2H4z M12,9H6V4h6V9z M3,5H1v9h10v-2H3V5z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
