import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonCircleRecord = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'circle record icon'}</title>
      <path
        id='circle-record'
        d='M8,0.035C12.406,0.035 15.983,3.612 15.983,8.017C15.983,12.423 12.406,16 8,16C3.594,16 0.017,12.423 0.017,8.017C0.017,3.612 3.594,0.035 8,0.035ZM8,2.035C4.698,2.035 2.017,4.716 2.017,8.017C2.017,11.319 4.698,14 8,14C11.302,14 13.983,11.319 13.983,8.017C13.983,4.716 11.302,2.035 8,2.035ZM7.989,5.017C9.636,5.017 10.972,6.354 10.972,8C10.972,9.646 9.636,10.983 7.989,10.983C6.343,10.983 5.007,9.646 5.007,8C5.007,6.354 6.343,5.017 7.989,5.017Z'
      />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
