import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { themeVal, glsp, disabled } from '@devseed-ui/theme-provider';
import React from 'react';
import { useButtonGroup } from './context';

export const createButtonStyles = (props) => {
  return css`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: ${glsp(0.25)};
    user-select: none;
    line-height: inherit;
    border: ${themeVal('button.shape.border')} solid transparent;
    background: transparent;
    font-family: ${themeVal('button.type.family')};
    font-weight: ${themeVal('button.type.weight')};
    font-style: ${themeVal('button.type.style')};
    font-variation-settings: ${themeVal('button.type.settings')};
    text-transform: ${themeVal('button.type.case')};
    outline: 0 solid transparent;
    text-decoration: none;
    transition: background-color 0.24s ease-in-out 0s,
      outline-width 0.16s ease-in-out 0s;

    &:focus-visible {
      outline-width: 0.25rem;
    }

    &:focus:not(:focus-visible) {
      outline: 0;
    }

    /* Disabled state based on prop. */
    ${renderDisabledState(props)}

    &[disabled] {
      ${disabled()}
    }

    /* Size. */
    ${renderButtonSize(props)}

    /* Variation. */
    ${renderButtonVariation(props)}

    /* Content Fit */
    ${renderButtonFitting(props)}

    /* Radius */
    &,
    &::after {
      ${renderButtonRadius(props)}
    }

    &::after {
      position: absolute;
      z-index: 10;
      top: -${themeVal('button.shape.border')};
      right: -${themeVal('button.shape.border')};
      bottom: -${themeVal('button.shape.border')};
      left: -${themeVal('button.shape.border')};
      content: '';
      background: transparent;
      pointer-events: none;
    }
  `;
};

// Styled component of a button
const StyledButton = styled.button.attrs((props) => {
  return {
    'aria-pressed': String(!!props.active),
    type: props.type || (!props.as ? 'button' : undefined)
  };
})`
  ${createButtonStyles}
`;

/* eslint-disable react/display-name, react/prop-types */
// Wrap the styled button in a high order component to pass the context
// provider. This component needs to also become a styled-component to take
// advantage of the styled component functionalities.

// To be sure that this component maintains its structure you MUST use the
// styled-component's `forwardedAs` property when replacing what's being
// rendered, instead of using `as`.
export const Button = styled(
  React.forwardRef((props, ref) => {
    const { size, variation, radius } = useButtonGroup();
    return (
      <StyledButton
        size={size}
        variation={variation}
        radius={radius}
        {...props}
        ref={ref}
      />
    );
  })
)`
  /* styled-component */
`;
/* eslint-enable */

Button.propTypes = {
  children: PropTypes.node,
  variation: PropTypes.string,
  size: PropTypes.string,
  radius: PropTypes.string,
  fitting: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool
};

/**
 * Renders the button disabled stated.
 *
 * @param {object} props The element props
 * @param {bool} props.disabled Whether the button should be disabled.
 */
function renderDisabledState(props) {
  return props.disabled
    ? disabled()
    : css`
        cursor: pointer;
      `;
}

/**
 * Renders the button size based on the props.
 *
 * @param {object} props The element props
 * @param {string} props.size The value for the box. One of
 *                 "small" | "medium" | "large" | "xlarge"
 */
function renderButtonSize(props) {
  const { size = 'medium' } = props;

  switch (size) {
    case 'small':
      return css`
        min-width: 1.5rem;
        height: 1.5rem;
        font-size: 0.875rem;
        padding: 0 0.5rem;
      `;
    case 'medium':
      return css`
        min-width: 2rem;
        height: 2rem;
        font-size: 1rem;
        padding: 0 0.75rem;
      `;
    case 'large':
      return css`
        min-width: 2.5rem;
        height: 2.5rem;
        font-size: 1rem;
        padding: 0 1rem;
      `;
    case 'xlarge':
      return css`
        min-width: 3rem;
        height: 3rem;
        font-size: 1rem;
        padding: 0 1.25rem;
      `;
    default:
      throw new Error(
        `Invalid button size (${size}). Must be one of [small, medium, large, xlarge].`
      );
  }
}

function renderButtonVariation(props) {
  const [color, type] = props.variation?.split('-') || ['base', 'text'];
  // Function to get the color from the theme.
  const tColor = (c) => themeVal(['color', c]);

  if (!['text', 'fill', 'outline'].includes(type)) {
    throw new Error(
      `Invalid button type (${type}). Must be one of [text, fill, outline].`
    );
  }

  if (color === 'achromic') {
    if (type === 'fill') {
      throw new Error(`achromic-fill buttons do not exist.`);
    }

    switch (type) {
      case 'text':
        return css`
          color: #fff;

          &:hover {
            background-color: ${rgba('#fff', 0.04)};
          }

          /* Print & when prop is passed */
          ${({ active }) => active && '&,'}
          &:active,
          &.active {
            background-color: ${rgba('#fff', 0.08)};
          }

          &:focus-visible {
            outline-color: ${rgba('#fff', 0.16)};
          }
        `;
      case 'outline':
        return css`
          border-color: #fff;
          color: #fff;

          &:hover {
            background-color: ${rgba('#fff', 0.04)};
          }

          /* Print & when prop is passed */
          ${({ active }) => active && '&,'}
          &:active,
          &.active {
            background-color: ${rgba('#fff', 0.04)};
          }

          &:focus-visible {
            outline-color: ${rgba('#fff', 0.16)};
          }
        `;
    }
  }

  switch (type) {
    case 'text':
      return css`
        color: ${tColor(color)};

        &:hover {
          background-color: ${tColor(`${color}-50a`)};
        }

        /* Print & when prop is passed */
        ${({ active }) => active && '&,'}
        &:active,
        &.active {
          background-color: ${tColor(`${color}-100a`)};
        }

        &:focus-visible {
          outline-color: ${tColor(`${color}-200a`)};
        }
      `;
    case 'outline':
      return css`
        border-color: ${tColor(color)};
        color: ${tColor(color)};

        &:hover {
          background-color: ${tColor(`${color}-50a`)};
        }

        /* Print & when prop is passed */
        ${({ active }) => active && '&,'}
        &:active,
        &.active {
          background-color: ${tColor(`${color}-100a`)};
        }

        &:focus-visible {
          outline-color: ${tColor(`${color}-200a`)};
        }
      `;
    case 'fill':
      return css`
        border-color: ${themeVal('color.base-200a')};
        background-color: ${tColor(color)};
        color: #fff;

        &:hover {
          background-color: ${tColor(`${color}-600`)};
        }

        /* Print & when prop is passed */
        ${({ active }) => active && '&,'}
        &:active,
        &.active {
          background-color: ${tColor(`${color}-700`)};
        }

        &:focus-visible {
          outline-color: ${tColor(`${color}-200a`)};
        }

        &::after {
          box-shadow: ${themeVal('boxShadow.elevationB')};
        }
      `;
  }
}

/**
 * Renders the border radius based on the props.
 *
 * @param {object} props The element props
 * @param {string} props.radius The value for the radius. One of
 *                 "ellipsoid" | "square" | "rounded"
 */
function renderButtonRadius(props) {
  const { radius = 'rounded' } = props;

  switch (radius) {
    case 'rounded':
      return css`
        border-radius: ${themeVal('shape.rounded')};
      `;
    case 'ellipsoid':
      return css`
        border-radius: ${themeVal('shape.ellipsoid')};
      `;
    case 'square':
      return css`
        border-radius: 0;
      `;
    default:
      throw new Error(
        `Invalid button radius (${radius}). Must be one of [rounded, ellipsoid, square].`
      );
  }
}

/**
 * Renders the content fit based on the props.
 *
 * @param {object} props The element props
 * @param {string} props.fitting The value for the content fit. One of
 *                 "skinny" | "regular" | "relaxed" | "baggy"
 */
function renderButtonFitting(props) {
  const { fitting = 'regular' } = props;

  switch (fitting) {
    case 'skinny':
      return css`
        padding-left: 0;
        padding-right: 0;
      `;
    case 'regular':
      return '';
    case 'relaxed':
      return css`
        min-width: 12rem;
      `;
    case 'baggy':
      return css`
        width: 100%;
      `;
    default:
      throw new Error(
        `Invalid button fitting (${fitting}). Must be one of [skinny, regular, relaxed, baggy].`
      );
  }
}
