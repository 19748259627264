import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonChevronStepForward = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'chevron step forward icon'}</title>
      <path d='M14,1h-2v14h2V1z M7.2,8l-5.6,5.6L3,15l7-7L3,1L1.6,2.4L7.2,8z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
