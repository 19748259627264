import React from 'react';
import T from 'prop-types';
import styled, { css } from 'styled-components';

import { themeVal } from '@devseed-ui/theme-provider';

import { Button } from './Button';
import { ButtonGroupProvider } from './context';

/**
 * Renders the button group orientation based on the props.
 *
 * @param {object} props The element props
 * @param {string} props.orientation The value for the orientation. One of
 *                 "horizontal" | "vertical"
 */
function renderOrientation(props) {
  const { orientation = 'horizontal' } = props;

  switch (orientation) {
    case 'horizontal':
      return css`
        flex-flow: row nowrap;
        > ${Button}:first-child:not(:last-child) {
          &,
          &::after {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          &::after {
            clip-path: inset(-100% 0 -100% -100%);
          }
        }
        > ${Button}:last-child:not(:first-child) {
          &,
          &::after {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          &::after {
            clip-path: inset(-100% -100% -100% 0);
          }
        }
        > ${Button}:not(:first-child):not(:last-child) {
          &,
          &::after {
            border-radius: 0;
          }

          &::after {
            clip-path: inset(-100% 0);
          }
        }
        > ${Button} + ${Button} {
          margin-left: -${themeVal('button.shape.border')};
        }
      `;
    case 'vertical':
      return css`
        flex-flow: column;
        > ${Button}:first-child:not(:last-child) {
          &,
          &::after {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }

          &::after {
            clip-path: inset(-100% -100% 0 -100%);
          }
        }
        > ${Button}:last-child:not(:first-child) {
          &,
          &::after {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }

          &::after {
            clip-path: inset(0 -100% -100% -100%);
          }
        }
        > ${Button}:not(:first-child):not(:last-child) {
          &,
          &::after {
            border-radius: 0;
          }

          &::after {
            clip-path: inset(0 -100%);
          }
        }
        > ${Button} + ${Button} {
          margin-top: -${themeVal('button.shape.border')};
        }
      `;
    default:
      throw new Error(
        `Invalid button group orientation (${orientation}). Must be one of [horizontal, vertical].`
      );
  }
}

export const createButtonGroupStyles = (props) => {
  return css`
    position: relative;
    display: inline-flex;

    > ${Button} {
      position: relative;
      flex: 1 1 auto;

      &:focus {
        z-index: 1;
      }
    }

    /* Group orientation */
    ${renderOrientation(props)}
  `;
};

// Styled component of the button group.
const StyledButtonGroup = styled.div`
  ${createButtonGroupStyles}
`;

/* eslint-disable react/display-name, react/prop-types */
// Wrap the styled button in a high order component to pass the context
// provider. This component needs to also become a styled-component to take
// advantage of the styled component functionalities.

// To be sure that this component maintains its structure you MUST use the
// styled-component's `forwardedAs` property when replacing what's being
// rendered, instead of using `as`.
export const ButtonGroup = styled(
  React.forwardRef((props, ref) => {
    const {
      className,
      children,
      size,
      variation,
      radius,
      role = 'group',
      ...rest
    } = props;
    return (
      <ButtonGroupProvider size={size} variation={variation} radius={radius}>
        <StyledButtonGroup
          className={className}
          role={role}
          ref={ref}
          {...rest}
        >
          {children}
        </StyledButtonGroup>
      </ButtonGroupProvider>
    );
  })
)`
  /* styled-component */
`;
/* eslint-enable */

ButtonGroup.propTypes = {
  children: T.node,
  className: T.string,
  size: T.string,
  variation: T.string,
  radius: T.string,
  role: T.string
};
