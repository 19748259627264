import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonSubscript = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'subscript icon'}</title>
      <path d='M13,12l0,-1l2,0c0.552,0 1,0.448 1,1l0,1c-0,0.552 -0.448,1 -1,1l-0.75,0c-0.066,-0 -0.13,0.026 -0.177,0.073c-0.047,0.047 -0.073,0.111 -0.073,0.177c0,0.153 0,0.347 0,0.5c-0,0.066 0.026,0.13 0.073,0.177c0.047,0.047 0.111,0.073 0.177,0.073l1.75,0l0,1l-2,0c-0.552,-0 -1,-0.448 -1,-1l0,-1c0,-0.552 0.448,-1 1,-1l0.75,-0c0.066,0 0.13,-0.026 0.177,-0.073c0.047,-0.047 0.073,-0.111 0.073,-0.177c0,-0.153 0,-0.347 0,-0.5c0,-0.066 -0.026,-0.13 -0.073,-0.177c-0.047,-0.047 -0.111,-0.073 -0.177,-0.073l-1.75,0Zm-4.586,-4l4,4l-2.828,0l-2.586,-2.586l-2.586,2.586l-2.828,0l4,-4l-4,-4l2.828,0l2.586,2.586l2.586,-2.586l2.828,0l-4,4Z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
