import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonTriangleLeft = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'triangle left icon'}</title>
      <polygon points='3,8 11,0 11,16' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
