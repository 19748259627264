import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonSquare = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'square icon'}</title>
      <rect x='2' y='2' width='12' height='12' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
