import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonHouse = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'house icon'}</title>
      <polygon points='0,6 0,16 6,16 6,10 10,10 10,16 16,16 16,6 8,0' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
