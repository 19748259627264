import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonGlobe = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'globe icon'}</title>
      <path d='M8,0C3.581,0,0,3.581,0,8s3.581,8,8,8s8-3.581,8-8S12.419,0,8,0z M3.756,12.244C2.625,11.109,2,9.603,2,8 c0-0.569,0.078-1.122,0.231-1.653l0.506,1.334l2.819,2.225l0.469,0.106l-0.691,1.106l1.022,2.653 C5.384,13.497,4.494,12.978,3.756,12.244z M12.822,11.575c-0.175,0.234-0.369,0.459-0.578,0.669 c-1.084,1.084-2.512,1.703-4.041,1.753l1.678-1.325l0.216-1.131L5.856,9.569L5.594,9.112L5.25,8.875L5.072,8.091L4.491,8.328 l0.194-0.762l0.694-0.091l0.619,0.688L6.2,6.975l0.759-1.05l0.809-0.287L7.85,5.091L7.675,4.903l0.766-0.125l0.087-0.6L7.834,3.462 L7.241,3.541L6.506,3.175l-0.4,1.253L5.222,3.891l1.284-1.344L4.862,2.881c0.831-0.509,1.772-0.809,2.759-0.869L9.484,3.65 l0.684,0.184l0.144-0.528l0.703-0.491c0.441,0.256,0.85,0.572,1.219,0.941c0.184,0.184,0.356,0.381,0.516,0.584l-0.544,0.941 l-0.534,0.106l0.656,4.137l0.731,0.766L12.822,11.575z M12.859,6.191l0.247-0.331L13.387,6.2L12.859,6.191z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
