import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonChevronRight = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'chevron right icon'}</title>
      <polygon points='3.586,1.414 10.172,8 3.586,14.586 5,16 13,8 5,0' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
