import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonArrowUp = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'arrow up icon'}</title>
      <polygon points='2.414,8.414 7,3.828 7,16 9,16 9,3.828 13.586,8.414 15,7 8,0 1,7' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
