import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonPages = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'pages icon'}</title>
      <path d='M3,3H1v13h11v-2H3V3z M12,0H4v13h11V3L12,0z M13,11H6V2h5v2h2V11z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
