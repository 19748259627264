import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonSpark = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'spark icon'}</title>
      <path d='M10,1L3,9h4.5L6,15l7-8H8.5L10,1z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
