import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonBrandSkype = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'brand skype icon'}</title>
      <path d='M15.239,9.03c0.044-0.325,0.069-0.655,0.069-0.992c0-4.036-3.29-7.309-7.347-7.309c-0.403,0-0.799,0.033-1.183,0.096 c-0.69-0.449-1.511-0.711-2.394-0.711C1.961,0.115,0,2.078,0,4.499c0,0.881,0.259,1.7,0.706,2.387 c-0.06,0.376-0.091,0.76-0.091,1.152c0,4.037,3.288,7.309,7.346,7.309c0.458,0,0.904-0.045,1.337-0.123 c0.673,0.418,1.467,0.661,2.317,0.661c2.422,0,4.384-1.963,4.384-4.385C16,10.585,15.72,9.734,15.239,9.03z M8.648,13.425 c-2.335,0.122-3.427-0.395-4.428-1.334c-1.118-1.049-0.669-2.245,0.242-2.306c0.91-0.061,1.456,1.032,1.942,1.335 c0.485,0.303,2.331,0.993,3.306-0.122c1.062-1.213-0.706-1.841-2.002-2.031c-1.85-0.274-4.186-1.275-4.004-3.247 C3.886,3.748,5.379,2.738,6.95,2.595c2.002-0.182,3.306,0.303,4.337,1.183c1.192,1.017,0.547,2.153-0.212,2.244 C10.318,6.114,9.468,4.351,7.8,4.324C6.08,4.297,4.918,6.114,7.041,6.63c2.124,0.516,4.398,0.728,5.218,2.669 C13.078,11.241,10.985,13.303,8.648,13.425z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
