import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonPlus = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'plus icon'}</title>
      <polygon points='15,7 9,7 9,1 7,1 7,7 1,7 1,9 7,9 7,15 9,15 9,9 15,9' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
