import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonArrowDown = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'arrow down icon'}</title>
      <polygon points='13.586,7.586 9,12.172 9,0 7,0 7,12.172 2.414,7.586 1,9 8,16 15,9' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
