import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonWay = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'way icon'}</title>
      <path d='M15,3L15,4.268C15.598,4.614 16,5.26 16,6C16,7.104 15.104,8 14,8C12.896,8 12,7.104 12,6C12,5.26 12.402,4.614 13,4.268L13,3.5C13,2.672 12.328,2 11.5,2C10.672,2 10,2.672 10,3.5L10,12L10,12.013C9.993,14.215 8.203,16 6,16C3.792,16 2,14.208 2,12L2,11.829C0.835,11.417 0,10.305 0,9C0,7.344 1.344,6 3,6C4.656,6 6,7.344 6,9C6,10.305 5.165,11.417 4,11.829L4,12.006C4,12.108 4.008,12.21 4.024,12.311C4.049,12.471 4.094,12.629 4.157,12.778C4.254,13.008 4.395,13.219 4.569,13.397C4.736,13.568 4.933,13.709 5.15,13.81C5.336,13.898 5.535,13.957 5.739,13.983C5.958,14.012 6.181,14.004 6.397,13.961C6.583,13.923 6.764,13.859 6.932,13.77C7.13,13.665 7.311,13.527 7.464,13.362C7.615,13.2 7.738,13.014 7.828,12.812C7.898,12.654 7.948,12.487 7.975,12.317C7.992,12.212 8,12.106 8,12L8,3.5C8,1.568 9.568,0 11.5,0C13.262,0 14.721,1.305 14.964,3L15,3ZM3,8C2.448,8 2,8.448 2,9C2,9.552 2.448,10 3,10C3.552,10 4,9.552 4,9C4,8.448 3.552,8 3,8Z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
