import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonFolder = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'folder icon'}</title>
      <path d='M7,1l2,2h7v11H0V1H7z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
