import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonTriangleDown = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'triangle down icon'}</title>
      <polygon points='8,13 0,5 16,5' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
