import styled, { css } from 'styled-components';

import { glsp, rem, themeVal } from '@devseed-ui/theme-provider';
import {
  createHeadingStyles,
  createSubtitleStyles,
  renderHeadingSize,
  createTableStyles,
  renderTableZebra
} from '.';

const proseSpacing = 1.5;

export const createProseStyles = () => css`
  sup,
  sub {
    font-size: 0.75em;
  }

  sup {
    vertical-align: top;
  }

  sub {
    vertical-align: bottom;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${createHeadingStyles}
    margin-bottom: ${glsp()};

    &:not(:first-child) {
      margin-top: ${glsp(proseSpacing * 2)};
    }
  }

  h1 {
    ${renderHeadingSize({ size: 'xxlarge' })}
  }

  h2 {
    ${renderHeadingSize({ size: 'xlarge' })}
  }

  h3 {
    ${renderHeadingSize({ size: 'large' })}
  }

  h4 {
    ${renderHeadingSize({ size: 'medium' })}
  }

  h5 {
    ${renderHeadingSize({ size: 'small' })}
  }

  h6 {
    ${renderHeadingSize({ size: 'xsmall' })}
  }

  dl {
    padding: 0;
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }

  dt {
    font-weight: ${themeVal('type.base.bold')};
  }

  figure > figcaption {
    ${createSubtitleStyles}
    padding: ${glsp()};
    text-align: center;
    width: 100%;
    max-width: 52rem;
    margin: 0 auto;
  }

  figure img {
    display: block;
    margin: 0 auto;
  }

  > * {
    margin-bottom: ${rem(proseSpacing)};
  }

  > *:last-child {
    margin-bottom: 0;
  }

  table {
    ${createTableStyles}

    &.table-zebra {
      ${renderTableZebra({ zebra: true })}
    }
  }
`;

export const Prose = styled.div`
  ${createProseStyles}
`;
