import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonChevronLeft = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'chevron left icon'}</title>
      <polygon points='12.414,14.586 5.828,8 12.414,1.414 11,0 3,8 11,16 ' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
