import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonTextBlock = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'text block icon'}</title>
      <path d='M0,10h16V8H0V10z M0,0v2h16V0H0z M0,14h8v-2H0V14z M0,6h16V4H0V6z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
