import styled, { css } from 'styled-components';

import { themeVal } from '@devseed-ui/theme-provider';

const renderHeadingVariation = (props = {}) => {
  const { variation } = props;

  if (!variation) {
    return css`
      color: inherit;
    `;
  } else {
    return css`
      color: ${themeVal(['color', variation])};
    `;
  }
};

const sizeMapping = {
  xxsmall: '0.75rem',
  xsmall: '1rem',
  small: '1.25rem',
  medium: '1.5rem',
  large: '1.75rem',
  xlarge: '2rem',
  xxlarge: '2.25rem',
  jumbo: '3rem'
};

export const renderHeadingSize = (props = {}) => {
  const { size = 'medium' } = props;

  return css`
    font-size: ${sizeMapping[size]};
    ${size === 'xxsmall' && 'line-height: 1rem;'}
  `;
};

export const createHeadingStyles = (props) => css`
  font-family: ${themeVal('type.heading.family')};
  text-transform: ${themeVal('type.heading.case')};
  font-weight: ${themeVal('type.heading.weight')};
  font-style: ${themeVal('type.heading.style')};
  font-variation-settings: ${themeVal('type.heading.settings')};

  /* Variation */
  ${renderHeadingVariation(props)}

  /* Size */
  ${renderHeadingSize(props)}
`;

export const createOverlineStyles = (props = {}) => css`
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: ${themeVal('type.overline.family')};
  font-weight: ${themeVal('type.overline.regular')};
  text-transform: ${themeVal('type.overline.textTransform')};
  font-feature-settings: ${themeVal('type.overline.settings')};

  /* Variation */
  ${renderHeadingVariation({
    ...props,
    variation: props.variation || 'base-400'
  })}
`;

export const createSubtitleStyles = () => css`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${themeVal('color.base-400')};
`;

export const Heading = styled.h1`
  ${createHeadingStyles}
`;

export const Overline = styled.p`
  ${createOverlineStyles}
`;

export const Lead = styled.p`
  font-size: ${themeVal('type.base.leadSize')};
  color: ${themeVal('color.base-400')};
`;

export const Subtitle = styled.p`
  ${createSubtitleStyles}
`;
