import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonHeading = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'heading icon'}</title>
      <path
        id='heading'
        d='M11,8l-6,0l0,-7l-2,0l0,14l2,0l0,-5l6,0l0,5l2,0l0,-14l-2,0l0,7Z'
      />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
