import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonChevronLeftSmall = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'chevron left small icon'}</title>
      <polygon points='11.414,12.586 6.828,8 11.414,3.414 10,2 4,8 10,14' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
