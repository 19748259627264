import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonEqualSmall = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'equal small icon'}</title>
      <path d='M2,11h12V9H2V11z M2,5v2h12V5H2z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
