import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonPlusSmall = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'plus small icon'}</title>
      <polygon points='14,7 9,7 9,2 7,2 7,7 2,7 2,9 7,9 7,14 9,14 9,9 14,9' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
