import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonChevronRightSmall = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'chevron right small icon'}</title>
      <polygon points='4.586,3.414 9.172,8 4.586,12.586 6,14 12,8 6,2' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
