import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonLogin = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'login icon'}</title>
      <path d='M14,14l0,-12l-6,0l0,-2l8,0l0,16l-8,0l0,-2l6,0Zm-6.998,-0.998l4.998,-5.002l-5,-5l-1.416,1.416l2.588,2.584l-8.172,0l0,2l8.172,0l-2.586,2.586l1.416,1.416Z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
