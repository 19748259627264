import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonQuoteRight = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'quote right icon'}</title>
      <path d='M0,8h5c0,3.084-1.916,5-5,5v2c4.188,0,7-2.812,7-7V1H0V8z M9,1v7h5c0,3.084-1.916,5-5,5v2c4.188,0,7-2.812,7-7V1H9z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
