import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonArrowDownLeft = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'arrow down left icon'}</title>
      <path d='M12,12L5.414,12L15.021,2.393L13.607,0.979L4,10.586L4,4L2,4L2,14L12,14L12,12Z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
