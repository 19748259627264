import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonChevronUp = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'chevron up icon'}</title>
      <polygon points='1.414,12.414 8,5.828 14.586,12.414 16,11 8,3 0,11' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
