import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonEqual = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'equal icon'}</title>
      <path d='M1,11h14V9H1V11z M1,5v2h14V5H1z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
