import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonMinusSmall = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'minus small icon'}</title>
      <polygon points='14,7 2,7 2,9 14,9' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
