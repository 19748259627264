import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonArrowRevert = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'arrow revert icon'}</title>
      <path d='M0,0l2,0l0,2.709c1.466,-1.662 3.609,-2.709 6,-2.709c4.419,0 8,3.581 8,8c0,4.419 -3.581,8 -8,8c-4.419,0 -8,-3.581 -8,-8l2,0c0,1.603 0.624,3.11 1.756,4.244c1.132,1.134 2.641,1.756 4.244,1.756c1.603,0 3.109,-0.625 4.244,-1.756c1.131,-1.135 1.756,-2.641 1.756,-4.244c0,-1.603 -0.625,-3.109 -1.756,-4.244c-1.135,-1.131 -2.641,-1.756 -4.244,-1.756c-1.603,0 -3.109,0.625 -4.244,1.756c-0.078,0.078 -0.156,0.16 -0.228,0.244l2.472,0l0,2l-6,0l0,-6Zm10.784,4.916l1.485,1.337l-5.169,5.747l-3.244,-3.206l1.406,-1.422l1.754,1.731l3.768,-4.187Z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
