import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonSuperscript2 = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'superscript 2 icon'}</title>
      <path
        id='superscript'
        d='M12,15l-2,0l0,-5l-6,0l0,5l-2,0l0,-9c0,-2.76 2.24,-5 5,-5c2.76,0 5,2.24 5,5l0,9Zm-2,-7l0,-2c0,-1.656 -1.344,-3 -3,-3c-1.656,0 -3,1.344 -3,3l0,2l6,0Zm3,-7l0,-1l2,0c0.552,0 1,0.448 1,1l0,1c-0,0.552 -0.448,1 -1,1l-0.75,0c-0.066,-0 -0.13,0.026 -0.177,0.073c-0.047,0.047 -0.073,0.111 -0.073,0.177c0,0.153 0,0.347 0,0.5c-0,0.066 0.026,0.13 0.073,0.177c0.047,0.047 0.111,0.073 0.177,0.073l1.75,0l0,1l-2,0c-0.552,-0 -1,-0.448 -1,-1l0,-1c0,-0.552 0.448,-1 1,-1l0.75,-0c0.066,0 0.13,-0.026 0.177,-0.073c0.047,-0.047 0.073,-0.111 0.073,-0.177c0,-0.153 0,-0.347 0,-0.5c0,-0.066 -0.026,-0.13 -0.073,-0.177c-0.047,-0.047 -0.111,-0.073 -0.177,-0.073l-1.75,0Z'
      />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
