import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonTriangleRight = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'triangle right icon'}</title>
      <polygon points='13,8 5,16 5,0' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
