import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonFilter = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'filter icon'}</title>
      <path d='M7,16L7,10L0,2L0,0L16,0L16,2L9,10L9,14L7,16Z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
