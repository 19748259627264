import React, { createContext, useContext, useMemo } from 'react';
import T from 'prop-types';

// The button group context is used so that the button group children can respond to
// global button group props, like the size.

// Context
const ButtonGroupContext = createContext({ inGroup: false });

// Context provider
export const ButtonGroupProvider = (props) => {
  const { children, size, radius, variation = 'base-fill' } = props;

  const contextValue = useMemo(
    () => ({ inGroup: true, size, radius, variation }),
    [size, radius, variation]
  );

  return (
    <ButtonGroupContext.Provider value={contextValue}>
      {children}
    </ButtonGroupContext.Provider>
  );
};

ButtonGroupProvider.propTypes = {
  children: T.node,
  size: T.string,
  radius: T.string,
  variation: T.string
};

// Context consumer
export const useButtonGroup = () => {
  // It should be possible to use the hook outside the button group to allow
  // component reusability.
  return useContext(ButtonGroupContext);
};
