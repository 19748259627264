import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonMinus = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'minus icon'}</title>
      <polygon points='15,7 1,7 1,9 15,9' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
