import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonTriangleDownSmall = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'triangle down small icon'}</title>
      <polygon points='8,11 3,6 13,6' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
