import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonArrowUpLeft = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'arrow up left icon'}</title>
      <path d='M4,12L4,5.414L13.607,15.021L15.021,13.607L5.414,4L12,4L12,2L2,2L2,12L4,12Z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
