import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonMap = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'map icon'}</title>
      <path d='M0,3l5-2v12l-5,2V3z M11,3v12l-5-1.667v-12L11,3z M16,1v12l-4,1.6v-12L16,1z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
