import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonExpandTopLeft = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'expand top left icon'}</title>
      <path d='M13,13H5V9H3v6h12V3H9v2h4V13z M2,8V3.413l6.294,6.294l1.413-1.413L3.412,2H8V0L0,0l0,8H2z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
