import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonChevronUpSmall = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'chevron up small icon'}</title>
      <polygon points='3.414,11.414 8,6.828 12.586,11.414 14,10 8,4 2,10' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
