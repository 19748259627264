import React from 'react';
import styled from 'styled-components';

import { createCollecticon } from '../collecticon-creator';

export const CollecticonArrowDownRight = styled(
  createCollecticon((props) => (
    <svg {...props}>
      <title>{props.title || 'arrow down right icon'}</title>
      <path d='M12,4L12,10.586L2.393,0.979L0.979,2.393L10.586,12L4,12L4,14L14,14L14,4L12,4Z' />
    </svg>
  ))
)`
  /* icons must be styled-components */
`;
